const resource = {
    en: {
        heading: 'Learning Modules',
        description: '<em>Content not yet available in english.</em><br><br>Hier entstehen in naher Zeit 5 Lernmodule, welche ergänzend zur Planungsmatrix verwendet werden können. <br><br><strong>Technische Grundlagen</strong>: Geschichtsdidaktische Theorie, Didaktik der Politische Bildung auf welcher die Matrix aufbaut: Systemtheorie, Prozessorientierung, Dimensionen des historischen Lernens, Historisches Denken, Prinzipien des politischen Denkens, Kommunikation im Lernprozess, soziale Kompetenzen von Lehrerinnen/Lehrern u.a.<br><br><strong>Unterrichtsbeobachtung</strong>: Die methodisch-theoretischen Grundlagen zur Unterrichtsbeobachtung: Beobachtungskriterien, Beobachtungsmethoden, Auswertung von Unterrichtsbeobachtung u.a.<br><br><strong>Unterrichtsplanung</strong>: Die methodisch-theoretischen Grundlagen zur Unterrichtsplanung: Adressat*innenanalyse, Umgehen mit Lehrplänen, Fachkompetenzen, Unterrichtsprinzipien, Unterrichtsmethoden (hierarchische, teamorientierte und prozessorientierte Methoden), Rückkoppelung, Überprüfungen und Anwendungen u.a.<br><br><strong>Unterrichtsreflexion</strong>: Die methodisch-theoretischen Grundlagen zur Unterrichtsreflexion: Reflexionsmethoden, Bereiche der Selbstreflexion, Analysemethoden von Unterrichtsplanung, Schlüsse aus Reflexionsergebnissen u.a.<br><br><strong>Ressourcen</strong>: Bereitstellung von und/oder Verlinkung zu Ressourcen, die eine prozessorientierte Unterrichtsplanung unterstützen bzw. auch modellhaft verdeutlichen.',
    },
    de: {
        heading: 'Lernmodule',
        description: 'Hier entstehen in naher Zeit 5 Lernmodule, welche ergänzend zur Planungsmatrix verwendet werden können. <br><br><strong>Technische Grundlagen</strong>: Geschichtsdidaktische Theorie, Didaktik der Politische Bildung auf welcher die Matrix aufbaut: Systemtheorie, Prozessorientierung, Dimensionen des historischen Lernens, Historisches Denken, Prinzipien des politischen Denkens, Kommunikation im Lernprozess, soziale Kompetenzen von Lehrerinnen/Lehrern u.a.<br><br><strong>Unterrichtsbeobachtung</strong>: Die methodisch-theoretischen Grundlagen zur Unterrichtsbeobachtung: Beobachtungskriterien, Beobachtungsmethoden, Auswertung von Unterrichtsbeobachtung u.a.<br><br><strong>Unterrichtsplanung</strong>: Die methodisch-theoretischen Grundlagen zur Unterrichtsplanung: Adressat*innenanalyse, Umgehen mit Lehrplänen, Fachkompetenzen, Unterrichtsprinzipien, Unterrichtsmethoden (hierarchische, teamorientierte und prozessorientierte Methoden), Rückkoppelung, Überprüfungen und Anwendungen u.a.<br><br><strong>Unterrichtsreflexion</strong>: Die methodisch-theoretischen Grundlagen zur Unterrichtsreflexion: Reflexionsmethoden, Bereiche der Selbstreflexion, Analysemethoden von Unterrichtsplanung, Schlüsse aus Reflexionsergebnissen u.a.<br><br><strong>Ressourcen</strong>: Bereitstellung von und/oder Verlinkung zu Ressourcen, die eine prozessorientierte Unterrichtsplanung unterstützen bzw. auch modellhaft verdeutlichen.',
    }
}

export default resource;