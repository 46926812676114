<template>
    <div class="learning-module">
      <img loading="lazy" :src="image_src" :alt="image_alt_text">
      <div class="info">
        <h2 v-html="title" />
        <p class="text" v-html="description" />
        <div>
          <router-link :to="link" v-html="resource[lang].read_more" />
        </div>
      </div>
    </div>
</template>

<script>
import resource from '@/assets/resources/homepage-learning-module-resource'

export default {
    props: {
        title: String,
        description: String,
        link: String,
        image_src: String,
        image_alt_text: String,
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
}
</script>

<style lang="scss" scoped>


.learning-module {
  grid-template-columns: 1fr 2fr;

  max-width: 70rem;

  div {
    margin-block-start: 1rem;
  }

  a {
    color: var(--dark_grey);
    font-family: var(--standard-font);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    text-decoration: underline;    
  }

  a:hover {
    color: var(--blak);
  }

  h2 {
    color: var(--blak);
    font-family: var(--standard-font);
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;

    margin-block-end: 1rem;
  }

  img {
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 1.8 / 1;
  }
}

</style>