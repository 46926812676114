<template>
    <div>
        <h1 class="page-heading" v-html="resource[lang].heading" />
        <div class="grid grid-4">
            <div class="text description" v-html="resource[lang].description" />
            <VerticalLearningModuleCard
                v-for="(module, index) in learning_modules"
                :key="module.id"
                :title="module.name[lang]"
                :description="module.description[lang]"
                :link="`/learning-module/${module.id}`"
                :image_src="images[index]"
                image_alt_text=""
            />
        </div>

    </div>
</template>

<script>
import resource from '@/assets/resources/learning-modules-resource'

import VerticalLearningModuleCard from '@/components/VerticalLearningModuleCard'

import { promiseGetRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        VerticalLearningModuleCard
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            learning_modules: [],
            images: [
                'https://images.unsplash.com/photo-1457369804613-52c61a468e7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
                'https://images.unsplash.com/photo-1606761568499-6d2451b23c66?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80',
                'https://images.unsplash.com/photo-1503551723145-6c040742065b-v2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
                'https://images.unsplash.com/photo-1586769852836-bc069f19e1b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
                'https://images.unsplash.com/photo-1495446815901-a7297e633e8d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
            ],

            resource
        }
    },
    async created() {
        const response = await this.learningModulesRequest();
        this.learning_modules = response.data;
    },
    methods: {
        // Requests
        learningModulesRequest() {
            return promiseGetRequest('learning-modules');
        }
    }
}
</script>

<style lang="scss" scoped>

.grid {
    column-gap: 1rem;
    row-gap: 5rem;
}

.description {
    grid-column: 1 / span 3;
}

@media (max-width: 50rem) {
  .flex,
  .grid {
    display: block;
  }

  .grid {
    div + div {
      margin-top: 3rem;
    }
  }

  .home {
    padding: 0.5rem;
  }
}

</style>